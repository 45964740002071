import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

/* Import menu dla podstron */
import HeaderP from "./HeaderP";

/* Przejście na górę strony po jej odświeżeniu */
class ScrollToTopOnMount extends Component {
  componentDidMount() {
    /* Resetowanie zapamiętanej pozycji przez niektóre przeglądarki (Chrome) */
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);
  }
  render() {
    return null
  }
}

{/* Generowanie strony polityki prywatności */}
class Polityka extends Component {
  render() {
    return (
      <Container fluid className="screensize" id="other_page">
        <ScrollToTopOnMount />

        {/* Załadowanie menu dla podstron */}
        <HeaderP />

        {/* Sekcja treści polityki prywatności */}
        <section className="gold-separator" id="polityka">
          <Container className="moving-show">
            <Row className="section-name">
              <Col lg={12} md={12} sm={12} xs={12}>
                <h1><Trans>Polityka prywatności</Trans></h1>
              </Col>
            </Row>
            <Row className="anime">
              <Col lg={12} md={12} sm={12} xs={12}>
                <h2>Administrator danych</h2>
                <p>Administratorem Państwa danych osobowych jest BUSINESSWISE Sp. z o.o. z&nbsp;siedzibą w Warszawie, ul. Pankiewicza 3, 00-696 Warszawa, wpisana do&nbsp;Rejestru Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie Wydział XII Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000728716, NIP: 7010819509 („<b>Administrator</b>”, „<b>my</b>”, „<b>nas</b>”).</p>

                <h2>Dane kontaktowe</h2>
                <p>W&nbsp;sprawach związanych z&nbsp;niniejszą polityką prywatności oraz w&nbsp;celu wykonywania swoich praw prosimy o&nbsp;kontakt z&nbsp;nami poprzez:</p>

                <p>e-mail: <a href="mailto:biuro@businesswise.pl">biuro@businesswise.pl</a> <br />
                lub <br />
                drogą pisemną, na&nbsp;adres naszej siedziby podany powyżej.</p>

                <h2>Jakie dane osobowe zbieramy?</h2>
                <p>Zbieramy Państwa dane osobowe podane w&nbsp;formularzu kontaktowym na&nbsp;naszej stronie internetowej oraz przekazane nam później, w&nbsp;trakcie rozmowy telefonicznej, korespondencji lub spotkania. Podanie danych osobowych jest dobrowolne.</p>

                <h2>Cele i&nbsp;podstawy prawne przetwarzania danych</h2>
                <p>Przetwarzamy Państwa dane osobowe w&nbsp;celu udzielenia odpowiedzi na&nbsp;zapytanie skierowane do&nbsp;nas przez formularz kontaktowy, email lub telefon, jak również w&nbsp;celu nawiązania i&nbsp;utrzymania relacji biznesowej z&nbsp;Państwem. Podstawą przetwarzania Państwa danych osobowych jest nasz uzasadniony interes.<sup> 1 </sup></p>

                <h2>Odbiorcy danych osobowych</h2>
                <p>Korzystamy z&nbsp;usług podmiotów działających na&nbsp;nasze zlecenie takich jak zewnętrzne firmy IT, firmy hostingowe, czy agencje reklamowe, które mogą mieć dostęp do&nbsp;Państwa danych osobowych. Jeżeli będą Państwo zainteresowani nawiązaniem z&nbsp;nami współpracy odbiorcami Państwa danych mogą być również domy maklerskie lub inne instytucje finansowe. Nie przekażemy Państwa danych osobowych poza teren Europejskiego Obszaru Gospodarczego.</p>

                <h2>Jak długo będziemy przechowywać Państwa dane osobowe?</h2>
                <p>Państwa dane osobowe będą przetwarzane przez okres niezbędny do&nbsp;udzielenia odpowiedzi na skierowane do&nbsp;nas zapytanie i&nbsp;prowadzenia wzajemnej korespondencji, jak również przez czas utrzymywania relacji biznesowych z&nbsp;Państwem.</p>

                <h2>Państwa prawa w&nbsp;odniesieniu do&nbsp;danych osobowych</h2>
                <p>Mają Państwo prawo dostępu do&nbsp;swoich danych osobowych i&nbsp;uzyskania ich kopii, sprostowania, usunięcia, ograniczenia oraz przenoszenia swoich danych osobowych. Mogą Państwo złożyć sprzeciw wobec przetwarzania Państwa danych osobowych dla naszych uzasadnionych interesów, w&nbsp;tym w&nbsp;celach marketingu bezpośredniego.</p>
                <p>W&nbsp;sprawach związanych z&nbsp;przetwarzaniem Państwa danych osobowych mogą Państwo złożyć skargę do&nbsp;Urzędu Ochrony Danych Osobowych.</p>
                <div className="little-gold-separator przypis-separator"></div>
                <div className="przypis">
                  <p><sup> 1  </sup>&nbsp;Podstawą prawną przetwarzania danych osobowych jest art. 6 ust. 1 lit. f Rozporządzenia Parlamentu Europejskiego i&nbsp;Rady (UE) 2016/679 z&nbsp;dnia 27 kwietnia 2016&nbsp;r. w&nbsp;sprawie ochrony osób fizycznych w&nbsp;związku z&nbsp;przetwarzaniem danych osobowych i&nbsp;w&nbsp;sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o&nbsp;ochronie danych).</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    );
  }
}

export default withTranslation(["translations"])(Polityka);